import ModalHeader from "../ModalHeader";
import classes from "../../../styles/GeneralUI/Modal/SiStatisticsToday.module.css";
import { FaQuestion } from "react-icons/fa";
import { FaBrain } from "react-icons/fa";
import SiStatisticsTodayLevel from "./SiStatisticsTodayLevel";
import { useEffect, useState } from "react";
import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";
import NextGameTimer from "../NextGameTimer";
import ModalFooter from "../ModalFooter";
import { Button } from "reactstrap";
import StatisticsModal from "../../../modals/StatisticsModal";
import RegisterModal from "../../../modals/RegisterModal";
import MydModalWithGrid from "../../../modals/MydModalWithGrid";

const levels_left = [
  { num: 1, leftText: "Stretching", rightText: "Unique" },
  { num: 2, leftText: "Novice", rightText: "Genius" },
  { num: 3, leftText: "Proficient", rightText: "Master" },
  { num: 4, leftText: "Expert", rightText: "Expert" },
  { num: 5, leftText: "Master", rightText: "Proficient" },
  { num: 6, leftText: "Genius", rightText: "Novice" },
  { num: 7, leftText: "Unique", rightText: "Fresher" },
];

const SiStatisticsToday = ({
  className,
  onClick,
  playedToday,
  currentRowNum,
  correctSiAnswers,
  hasCorrectGuess,
}) => {
  const { user, isAuthenticated } = useAuth0();

  
  let postFix = "th";
  if (currentRowNum === 1) postFix = "st";
  if (currentRowNum === 2) postFix = "nd";
  if (currentRowNum === 3) postFix = "rd";

  ////,......................TIMER................................................
  const remainingTime = new Date();
  if (playedToday || isAuthenticated) {
    let newDate = moment(new Date());

    const endTime = 24 * 3600;
    const now =
      newDate._i.getHours() * 60 * 60 +
      newDate._i.getMinutes() * 60 +
      newDate._i.getSeconds();

    const leftSeconds = endTime - now;

    remainingTime.setSeconds(remainingTime.getSeconds() + leftSeconds);
  }
  ////,.....................................................................
  return (
    <div className={classes.modal_container}>
      <div className={`${classes.backdrop} ${className}`} onClick={onClick} />
      <div className={classes.modal}>
        <ModalHeader>SiStatistics of today</ModalHeader>
        <div className={classes.statistics_texts__container}>
          {hasCorrectGuess ? (
            <div className={classes.modal_text}>
              Bravo! You found the{" "}
              <span className={classes.orange_colour}>siWord</span> of today at{" "}
              <span className={classes.red_colour}>
                {currentRowNum}
                {postFix}
              </span>{" "}
              attempt
            </div>
          ) : (
            <div className={classes.modal_text}>
              Oops! You could not find SiWord of today
            </div>
          )}
          <div className={classes.modal_text}>
            Correct <span className={classes.orange_colour}>siAnswers</span>:{" "}
            <span className={classes.red_colour}>{correctSiAnswers}</span>
          </div>
        </div>
        <div className={classes.icons_and_names__container}>
          <div className={classes.question_icon__and__q_name_container}>
            <p className={classes.icon_name}>SiQuestions</p>
            <FaQuestion
              size={45}
              color="#3565A3"
              className={classes.question_icon}
            />
          </div>
          <div className={classes.siword_icon__and__si_name_container}>
            <FaBrain
              size={47}
              color="#3565A3"
              className={classes.siword_icon}
            />
            <p className={classes.icon_name}>SiWord</p>
          </div>
        </div>

        <div className={classes.levels_container}>
          {levels_left.map((level, index) => {
            return (
              <SiStatisticsTodayLevel
                leftLevelColour={
                  level.num <= parseInt(correctSiAnswers) && "correct_box"
                }
                rightLevelColour={
                  level.num <= currentRowNum &&
                  currentRowNum <= 7 &&
                  hasCorrectGuess &&
                  "correct_box"
                }
                level={level.num}
                correctLeftTextColour={
                  level.num === parseInt(correctSiAnswers) && "correct_text"
                }
                correctRightTextColour={
                  level.num === currentRowNum &&
                  currentRowNum <= 7 &&
                  hasCorrectGuess &&
                  "correct_text"
                }
                leftText={level.leftText}
                rightText={level.rightText}
              />
            );
          })}
        </div>
        <div className={classes.game_timer__container}>
          <NextGameTimer expiryTimestamp={remainingTime} />
        </div>

        <div className={classes.modal_buttons_container}>
          {isAuthenticated && (
            // localStorage.clear(),
            <div className={classes.go_to_settings_text__container}>
              <Button className={classes.go_to_settings_text}>
                {/* Go to Settings */}
                <MydModalWithGrid username={user.sub} />
              </Button>
            </div>
          )}
          {
            <div className={classes.modal_buttons_container__noAuth}>
              {!isAuthenticated && (
                <div className={classes.why_register_button__container}>
                  <RegisterModal />
                </div>
              )}
              <StatisticsModal
                currentRowNum={currentRowNum}
                correctSiAnswers={correctSiAnswers}
              />
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default SiStatisticsToday;
