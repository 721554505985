import "./App.css";
import axios from "axios";

import insertStatistic from "./api/requests";
import Quiz, { QuizContext } from "./Components/SiQuiz/Quiz";
import { createContext, StrictMode, useEffect, useState } from "react";
import { emptyClueLetters } from "./defaultClueLetter";
import SiwordModal from "./Components/GeneralUI/Modal/SiwordModal";
import SiWord from "./Components/SiWord/SiWord";
import moment from "moment";

import { useAuth0 } from "@auth0/auth0-react";

import initFontAwesome from "./utils/initFontAwesome";

import { Navbar } from "./Components/GeneralUI/Header/Navbar/Navbar";
import GameName from "./Components/GeneralUI/Header/GameName";
import useSiword from "./hooks/useSiword";
import SiStatisticsToday from "./Components/GeneralUI/Modal/SiStatisticsToday";
import ReactGA from 'react-ga';
initFontAwesome();



export const AppContextProvider = createContext();

const yourDate = new Date();
let NewDate = moment(new Date(), "DD-MM-YYYY").format();
NewDate = NewDate.split("T")[0];

const Home = (props) => {
  const [welcomeModalVisible, setWelcomeModalVisible] = useState(false);
  const [siQuizModalVisible, setSiQuizModalVisible] = useState(false);
  const [quizEnded, setQuizEnded] = useState(false);
  const [clueLettersBoard, setClueLettersBoard] = useState(emptyClueLetters);
  const [gameOver, setGameOver] = useState(false);
  const [curRow, setCurRow] = useState(0);
  const [curRowStat, setCurRowStat] = useState(0);
  const [guessIsCorrect, setGuessIsCorrect] = useState(false);
  const [guessIsCorrectStats, setGuessIsCorrectStats] = useState(false);

  const [playedToday, setPlayedToday] = useState(false);

  const [userGuess, setUserGuess] = useState(0);
  const [correctQuizAnswers, setCorrectQuizAnswers] = useState(0);

  const [gamesPlayed, setGamesPlayed] = useState(0);
  const [gamesWon, setGamesWon] = useState(0);

  const [curDayAnswer, setCorectAnswerCount] = useState(0);

  const { user, isAuthenticated, loginWithRedirect } = useAuth0();

  const [lastPlaydate, setLastPlaydate] = useState();

  const [gamesPlayedToday, setGamesPlayedToday] = useState(false);

  let correctSiAnswers = 1;

  for (let i = 0; i < clueLettersBoard.length; i++) {
    if (clueLettersBoard[i]) {
      correctSiAnswers++;
    }
  }

  const getCurrentRow = (row) => {
    setCurRow(row);
    if (curRow === 6) {
      setCurRow(row + 1);
    }
  };

  const getLocalData = () => {
    const items = JSON.parse(localStorage.getItem("items"));

    const item_played = localStorage.getItem("gamesPlayed");
    const item_won = localStorage.getItem("gamesWon");

    if (items) {
      if (NewDate === items.last_time_played) {
        setPlayedToday(true);
        setUserGuess(items.user_guess);
        setCorrectQuizAnswers(items.question_answered);
        setGuessIsCorrect(items.find_answer_today);
        setLastPlaydate(items.last_time_played);
      } else {
      }
    }
    if (item_played && item_won) {
      setGamesPlayed(item_played);
      setGamesWon(item_won);
    }
  };

  function fetchStats(username) {
    axios
      .get("https://apisiword.siword.com/site/getstats?", {
        params: {
          username: username,
          last_play_date: NewDate,
        },
      })
      .then((res) => {
        setGamesPlayedToday(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function fetchUserStats(username) {
    axios
      .get("https://apisiword.siword.com/site/userstatistics?", {
        params: {
          username: username,
        },
      })
      .then((res) => {
        //setGuessIsCorrect(res.data.played_games);
        if (res.data.correct_guess > 0) {
          setGuessIsCorrectStats(true);
        } else {
          setGuessIsCorrectStats(false);
        }
        // setCurRow(res.data.correct_guess);
        setCurRowStat(res.data.correct_guess);
        setCorectAnswerCount(res.data.correct_answers);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getLocalData();
    const TRACKING_ID = "UA-255886465-1"; // OUR_TRACKING_ID
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(window.location.pathname);

  });

  useEffect(() => {
    if (isAuthenticated) {
      if (gamesPlayed != 0 && gamesPlayedToday != 0) {
        // localStorage.clear();
      }

      if (isAuthenticated) {
        fetchStats(user.sub);
        fetchUserStats(user.sub);
      }
    }
  });

  const handleHasCorrect = (boolValue) => {
    setGuessIsCorrect(boolValue);
  };

  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  // if (isLoading) {
  //   return <Loading />;
  // }

  return (
    // <SiStatisticsToday />
    <AppContextProvider.Provider
      value={{
        welcomeModalVisible,
        setWelcomeModalVisible,
        siQuizModalVisible,
        setSiQuizModalVisible,
        quizEnded,
        setQuizEnded,
        clueLettersBoard,
        setClueLettersBoard,
        gameOver,
        setGameOver,
        playedToday,
        gamesPlayed,
        gamesWon,
        correctQuizAnswers,
        userGuess,
        guessIsCorrect,
        lastPlaydate,
        gamesPlayedToday,
      }}
    >
      <Navbar />
      <GameName />
      <Quiz />
      {playedToday && (
        <SiStatisticsToday
          hasCorrectGuess={guessIsCorrect}
          currentRowNum={userGuess}
          correctSiAnswers={parseInt(correctQuizAnswers) + 1}
          playedToday={playedToday}
          // type="Statistics"
        />
      )}
      {quizEnded && (
        <SiWord
          hasCorrectGuess={handleHasCorrect}
          currentRow={getCurrentRow}
        ></SiWord>
      )}
      {/* {isAuthenticated && gameOver && !gamesPlayedToday && (
        <SiStatisticsToday
          hasCorrectGuess={guessIsCorrect}
          currentRowNum={curRow}
          correctSiAnswers={correctSiAnswers}
          playedToday={playedToday}
          // type="Statistics"
        />
      )} */}
      {isAuthenticated &&
        gamesPlayedToday &&
        (
        (
          <SiStatisticsToday
            hasCorrectGuess={guessIsCorrectStats}
            currentRowNum={parseInt(curRowStat)}
            correctSiAnswers={parseInt(curDayAnswer) + 1}
            playedToday={playedToday}
          />
        ))}

      {/* <StatisticsModal/> */}
    </AppContextProvider.Provider>
  );
};

export default Home;
