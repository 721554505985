import GameModal from "../GameModal";
import classes from "../../../styles/GeneralUI/GameModal.module.css";
import ModalHeader from "../ModalHeader";
import GameModalSubHeader from "../GameModalSubHeader";
import ModalSubHeaderText from "../ModalSubHeaderText";
import ModalFooter from "../ModalFooter";
import AppButton from "../AppButton";
import modal_data from "../../../data/modal.json";
import parse from "html-react-parser";
import RegisterModal from "../../../modals/RegisterModal";
import moment from "moment";

import StatisticsModal from "../../../modals/StatisticsModal";
import { Button } from "reactstrap";
import { useState } from "react";
import MydModalWithGrid from "../../../modals/MydModalWithGrid";
import { useAuth0 } from "@auth0/auth0-react";
import NextGameTimer from "../NextGameTimer";

const SiwordModal = ({
  className,
  onClick,
  type,
  visible,
  currentRowNum,
  correctSiAnswers,
  hasCorrectGuess,
  playedToday,
  // expiryTimestamp,
}) => {
  const { user, isAuthenticated } = useAuth0();
  ////,......................................................................
  const remainingTime = new Date();
  if (playedToday || isAuthenticated) {
    let newDate = moment(new Date());

    const endTime = 24 * 3600;
    const now =
      newDate._i.getHours() * 60 * 60 +
      newDate._i.getMinutes() * 60 +
      newDate._i.getSeconds();

    const leftSeconds = endTime - now;

    remainingTime.setSeconds(remainingTime.getSeconds() + leftSeconds);
  }
  ////,......................................................................

  const textWords = [];
  const statistics_data = [
    {
      attempt: currentRowNum,
    },
    {
      rightAnswers: correctSiAnswers + " of 6",
    },
  ];

  let modal_title = ""; // Title of the modal
  let sub_headers_arr = [];
  let modal_footer_arr = [];
  let modal_button_text = "";

  modal_data.forEach((data) => {
    if (data.title === type) {
      modal_title = type;
      const element_names = Object.keys(data);

      element_names.forEach((ele) => {
        if (ele === "sub_header") {
          sub_headers_arr = data[ele];
        }

        if (ele === "footer") {
          modal_footer_arr = data[ele];
        }

        if (ele === "button_text") {
          modal_button_text = data[ele];
        }
      });
    }
  });

  return (
    <div className={classes.modal_container}>
      <div className={`${classes.backdrop} ${className}`} onClick={onClick} />
      <div className={classes.modal}>
        <div className={classes.header_container}>
          {modal_title === "Welcome" ? (
            <ModalHeader>{modal_title}!</ModalHeader>
          ) : (
            <ModalHeader>{modal_title}</ModalHeader>
          )}
        </div>

        <div
          className={classes.submodals_container}
          style={
            modal_title === "Settings" || modal_title === "Statistics"
              ? {
                  marginTop: "3rem",
                }
              : modal_title === "Welcome"
              ? { marginTop: "0.5rem" }
              : modal_title === "Daily Story"
              ? { marginTop: "2rem" }
              : {}
          }
        >
          {sub_headers_arr.map((ele, index) => {
            const icon = ele["icon"];
            const text = ele["text"];

            let subheader = ele["title"];

            const subheader_data = statistics_data[index];
            let sta_data_keys = [];
            let statistics_value = 0;

            if (subheader_data) {
              sta_data_keys = Object.keys(subheader_data);
              statistics_value = subheader_data[sta_data_keys[0]];
            }

            const line_style = ele["line_style"];
            let single_text = ele["text"];
            let stat_value_text = "th";
            if (statistics_value === 1) stat_value_text = "st";
            if (statistics_value === 2) stat_value_text = "nd";
            if (statistics_value === 3) stat_value_text = "rd";

            if (type === "Statistics") {
              for (let i = 0; i < sta_data_keys.length; i++) {
                let key = sta_data_keys[i];

                if (key === "attempt") {
                  let startIndex = subheader.split(" ").indexOf("in");
                  if (!hasCorrectGuess) {
                    subheader = "You could not find siWord of today :(";
                  } else
                    subheader =
                      subheader.split(" ").slice(0, startIndex + 1) +
                      " " +
                      statistics_value +
                      stat_value_text +
                      " " +
                      subheader.split(" ").slice(startIndex + 1);
                }
              }
            }
            subheader = subheader.replaceAll(",", " ");

            return subheader ? (
              <GameModalSubHeader
                key={index}
                style={
                  modal_title === "Settings"
                    ? {
                        borderBottom: line_style,
                      }
                    : {}
                }
                sub_container_style={
                  modal_title === "Settings"
                    ? { marginLeft: "3.81%" }
                    : modal_title === "Statistics"
                    ? {
                        display: "flex",
                        justifyContent: "space-between",
                      }
                    : {}
                }
                pStyle={
                  modal_title === "Settings"
                    ? {
                        color: "black",
                        fontWeight: "500",
                        marginTop: "0.6rem",
                        marginBottom: "0.1rem",
                      }
                    : modal_title === "Statistics"
                    ? { fontWeight: "500" }
                    : {}
                }
                subheader={subheader}
                icon={icon}
              >
                {type === "Statistics"
                  ? sta_data_keys.map((ele) => (
                      <ModalSubHeaderText
                        textStyle={{
                          font: "Lato",
                          fontWeight: "500",
                          fontSize: "20px",
                          lineHeight: "1.2rem",
                          alignSelf: "center",
                        }}
                      >
                        {ele === "rightAnswers" && statistics_value}
                      </ModalSubHeaderText>
                    ))
                  : text.map((ele, index) => {
                      // colourSpecialWords(ele);
                      return (
                        <ModalSubHeaderText key={index} icon={icon}>
                          {parse(ele)}
                        </ModalSubHeaderText>
                      );
                    })}
              </GameModalSubHeader>
            ) : (
              <ModalSubHeaderText
                single_text_style={{
                  // backgroundColor: "red",
                  marginTop: "1.5rem",
                  width: "83%",
                  alignSelf: "center",
                }}
              >
                {/* <div className={classes.go_to_settings_text}>Settings</div> */}

                {/* {single_text} */}
              </ModalSubHeaderText>
            );
          })}
        </div>
        {/* <div className={classes.why_register_button__container}>
          <RegisterModal />
        </div> */}
        <div className={classes.game_timer__container}>
          <NextGameTimer expiryTimestamp={remainingTime} />
        </div>
        {/* <div style={{ height: "2rem" }}></div> */}
        {isAuthenticated && (
          <div className={classes.go_to_settings_text__container}>
            <Button className={classes.go_to_settings_text}>
              {/* Go to Settings */}
              <MydModalWithGrid username={user.sub} />
            </Button>
          </div>
        )}
        <div className={classes.footer_container}>
          {modal_footer_arr.map((ele, index) => (
            <ModalFooter key={index}>{ele}</ModalFooter>
          ))}
        </div>

        {modal_button_text.length > 0 && (
          <div className={classes.button_container}>
            <div className={classes.why_register_button__container}>
              <RegisterModal />
            </div>
            <StatisticsModal
              currentRowNum={currentRowNum}
              correctSiAnswers={correctSiAnswers}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SiwordModal;
