import axios from "axios";
import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import thirdIcon from "../assets/1-1.png";
import "../styles/ModalStyles/siStoryModal.css";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

import volcano from "../assets/volcano.png";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea, CardActions, SnackbarContent } from "@mui/material";

import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
} from "react-share";

import SubheaderText from "./SubHeaderTextModal";
import SubHeaderModal from "./SubHeaderModal";
import TitleModal from "./TitleModal";
import AppButton from "../Components/GeneralUI/AppButton";

import moment from "moment";
import PositionedSnackbar from "../snackbar/snackbar";
import { text } from "@fortawesome/fontawesome-svg-core";

const yourDate = new Date();
var NewDate = moment(new Date(), "DD-MM-YYYY").format();
NewDate = NewDate.split("T")[0];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },

  "& .css-46bh2p-MuiCardContent-root": {
    backgroundColor: "white !important",
  },
  "& .css-b-94lsxj-MuiButtonBase-root-MuiCardActionArea-root": {
    backgroundColor: "white !important",
  },
  "& .css-b6lsxj-MuiButtonBase-root-MuiCardActionArea-root": {
    backgroundColor: "white !important",
  },
  "& .css-1t6e9jv-MuiCardActions-root": {
    backgroundColor: "white !important",
    justifyContent: "space-between",
  },

  "& .MuiPaper-root": {
    borderRadius: "25px",
    backgroundColor: "#BEBDBD !important",
  },

  "& .MuiTypography-root": {
    color: "#3565A3 !important",
    fontFamily: "Nunito",
  },
  "& .css-r40f8v-MuiTypography-root": {
    fontSize: "1rem",
  },

  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiIconButton-root": {
    display: "none !important",
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          style={{
            backgroundColor: "#3565A3",
            border: "1.6px solid #D9D9D9",
            color: "#D9D9D9",
            fontSize: "50px",
          }}
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon
            style={{
              fontSize: "20px",
            }}
          />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const shareUrl = "http://demo.siword.com";
const title = "SiWord";
const siword_hashtag = "#sistory";

export default function CustomizedDialogs(props) {
  const [open, setOpen] = React.useState(false);
  const [datarow, setData] = React.useState([]);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const textAreaRef = React.useRef(null);
  // const [facebookClicked, setFacebookClicked] = React.useState(false);
  // const [twitterClicked, setTwitterClicked] = React.useState(false);

  React.useEffect(() => {
    axios
      .get("https://apisiword.siword.com/site/story?", {
        params: {
          date: story_date,
          status: 1,
        },
      })
      .then((res) => setData(res.data))
      .catch((err) => console.log(err));
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  if (props.date_added !== undefined) {
    var story_date = props.date_added;
  } else {
    var story_date = NewDate;
  }

  const copyToClipboardGeneralLogic = (story) => {
    setSnackbarOpen(true);
    setTimeout(async () => {
      await window.navigator.clipboard
        .writeText(story)
        .then(console.log("success"));
    }, 1000);
  };

  const copyToClipboardForTwitter = (story) => {
    copyToClipboardGeneralLogic(story);
    setTimeout(() => {
      window.location.href = "https://twitter.com/?lang=en";
    }, 3000);
  };

  const copyToClipboardForFacebook = (story) => {
    copyToClipboardGeneralLogic(story);
    setTimeout(() => {
      window.location.href = "https://www.facebook.com/";
    }, 3000);
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  return (
    <div>
      <div className="report-div-block">
        <img
          src={thirdIcon}
          className="button-story"
          onClick={handleClickOpen}
        />{" "}
        <p className="report-button-text">{props.button_title}</p>
      </div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogContent>
          <Card sx={{ maxWidth: 495 }}>
            <CardContent>
              <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
                align="center"
              >
                <TitleModal>SiStory of the Day!</TitleModal>
              </BootstrapDialogTitle>
            </CardContent>
            <CardActionArea>
              <CardContent>
                <SubHeaderModal>{datarow.title}</SubHeaderModal>
                <div onClick={() => copyToClipboardGeneralLogic(datarow.story)}>
                  <SubheaderText>{datarow.story}</SubheaderText>
                </div>
              </CardContent>
            </CardActionArea>

            <CardActions>
              <div className="facebook_twitter_buttons__container">
                <Tippy
                  content={
                    <span className="tool_tip__siStory">
                      CTRL+V to paste siStory
                    </span>
                  }
                >
                  {/* <FacebookShareButton
                    url={shareUrl}
                    quote={"SiStory of the day"}
                    hashtag={siword_hashtag}
                    className="Demo__some-network__share-button"
                    onClick={() => copyClipFacebook(datarow.story)}
                  > */}
                  <div
                    onClick={() => copyToClipboardForFacebook(datarow.story)}
                  >
                    <FacebookIcon size={32} round />
                  </div>
                  {/* </FacebookShareButton> */}
                </Tippy>

                <Tippy
                  content={
                    <span className="tool_tip__siStory">
                      CTRL+V to paste siStory
                    </span>
                  }
                >
                  {/* <TwitterShareButton
                    url={shareUrl}
                    quote={"SiStory of the day"}
                    hashtag={siword_hashtag}
                    className="Demo__some-network__share-button"
                    onClick={() => copyClipTwitter(datarow.story)}
                  > */}
                  <div onClick={() => copyToClipboardForTwitter(datarow.story)}>
                    <TwitterIcon size={32} round />
                  </div>
                  {/* </TwitterShareButton> */}
                </Tippy>

                <div className="face_tweet_empty__div"></div>
              </div>

              <div className="siStory_modal_AppButton">
                <AppButton
                  styleButton={{
                    backgroundColor: "#A2AB28",
                    height: "36px",
                  }}
                  styleP={{
                    color: "#D9D9D9",
                    color: "white",
                    fontSize: "16px",
                  }}
                  onClick={handleClose}
                >
                  Close
                </AppButton>
              </div>
              <div className="empty_div"></div>
            </CardActions>
          </Card>
        </DialogContent>
      </BootstrapDialog>
      {snackbarOpen && (
        <PositionedSnackbar
          hideDuration={2000}
          message="Copied To Clipboard "
          openSnack={snackbarOpen}
          onClose={handleCloseSnackBar}
        />
      )}
    </div>
  );
}
