import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import secondIcon from "../assets/2-1.png";
import "../styles/ModalStyles/InfoModal.css";
import whitePlay from "../assets/Play.png";
import Slide from "@mui/material/Slide";
import AppButton from "../Components/GeneralUI/AppButton";
import TitleModal from "./TitleModal";
import { AppContextProvider } from "../Home";
import Slideshow from "../slide-preview/slideshowwelcome";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiPaper-root": {
    borderRadius: "25px",
    backgroundColor: "#BEBDBD !important",
  },
  "& .MuiDialogContent-root": {
    paddingTop: "0",
    paddingBottom: "0",
    // textAlign: 'center'
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <div className="demo_info">
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            style={{
              backgroundColor: "#3565A3",
              border: "1.6px solid #D9D9D9",
              color: "#D9D9D9",
              fontSize: "50px",
            }}
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon style={{ fontSize: "20px" }} />
          </IconButton>
        ) : null}
      </DialogTitle>
    </div>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs() {
  const [open, setOpen] = React.useState(false);
  const { playedToday, gamesPlayedToday } =
    React.useContext(AppContextProvider);

  React.useEffect(() => {
    if (playedToday || gamesPlayedToday) {
      setOpen(false);
    }
  }, [playedToday, gamesPlayedToday]);

  const handleClickOpen = () => {
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          align="center"
        >
          <TitleModal>WELCOME!</TitleModal>
        </BootstrapDialogTitle>
        <DialogContent
          style={{ overflow: "", padding: "0" }}
          class="slideshow-app"
        >
          <Slideshow />
        </DialogContent>
        <DialogContent></DialogContent>

        <DialogActions className={"button"}>
          {!playedToday && (
            <AppButton
              styleButton={{ backgroundColor: "#A2AB28" }}
              styleP={{ color: "#D9D9D9" }}
              icon={whitePlay}
              autoFocus
              onClick={handleClose}
            >
              Let’s siQuestion!
            </AppButton>
          )}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
