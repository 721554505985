import axios from "axios";
import ClueLettersBox from "../GeneralUI/Header/ClueLettersBox";
import GameName from "../GeneralUI/Header/GameName";
import IntersectionLine from "../GeneralUI/Header/IntersectionLine";
import classes from "../../styles/SiQuiz/Quiz.module.css";
import Question from "./GameField/Question";
import { createContext, useContext, useState, useEffect } from "react";
import Questions from "../../data/questions.json";
// import VariantBox from "./GameField/VariantBox";
import VariantBox from "./GameField/VariantBox";
import AppButton from "../GeneralUI/AppButton";
import NumbersBox from "./GameField/NumbersBox";
import OpenQuestion from "./GameField/OpenQuestion";
import MenuBox from "../GeneralUI/MenuBox";
// import { AppContextProvider } from "../../App";
import SiQuizModal from "../GeneralUI/SiQuizModal";
import { AppContextProvider } from "../../Home";
import greenPlayIcon from "../../assets/greenPlay.png";
import { Navbar } from "../GeneralUI/Header/Navbar/Navbar";
import moment from "moment";
import ReactGA from 'react-ga';
export const QuizContext = createContext("");

const Quiz = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [className, setClassName] = useState("variant_box");
  const [answerSelected, setAnswerSelected] = useState(false);
  const [correctNums, setCorrectNums] = useState([]);
  const [wrongNums, setWrongNums] = useState([]);
  const [clueLetterPosition, setClueLetterPosition] = useState();
  const [quizIsFinished, setQuizIsFinished] = useState(false);
  const [correctAnswerCount, setCorrectAnswerCount] = useState(0);
  const { clueLettersBoard, setClueLettersBoard } =
    useContext(AppContextProvider);

  const [x, setX] = useState();
  const [y, setY] = useState();

  const sendDataToParent = (index) => {
    // the callback. Use a better name
    setQuizIsFinished(index);
  };

  const yourDate = new Date();
  var NewDate = moment(new Date(), "DD-MM-YYYY").format();
  NewDate = NewDate.split("T")[0];

  const [firstclue, setFirstClue] = useState();

  const [questions, setPosts] = useState([]);
  useEffect(() => {
    axios
      .get("https://apisiword.siword.com/site/checkword?", {
        params: {
          date: NewDate,
          status: 1,
        },
      })
      .then((res) => setPosts(res.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get("https://apisiword.siword.com/site/getsiclue?", {
        params: {
          qustion_number: 0,
          date: NewDate,
          status: 1,
        },
      })
      .then((res) => {
        clueLettersBoard[0] = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  let variantLetters = [];
  let demo;
  if (currentQuestion !== 0 && currentQuestion < 7) {
    //demo = Questions[currentQuestion].demo[0];

    demo = JSON.parse(questions[currentQuestion].demo[0]);

    variantLetters = Object.keys(demo);
  }

  const StartQuizHandler = () => {
    ReactGA.pageview('start quiz');
    setCorrectNums([1]);
    setCurrentQuestion(currentQuestion + 1);
  };

  return (
    <>
      <div className={classes.quiz_page_container}>
        <QuizContext.Provider
          value={{
            currentQuestion,
            setCurrentQuestion,
            selectedAnswer,
            setSelectedAnswer,
            className,
            setClassName,
            answerSelected,
            setAnswerSelected,
            correctNums,
            setCorrectNums,
            wrongNums,
            setWrongNums,
            clueLettersBoard,
            setClueLettersBoard,
            clueLetterPosition,
            setClueLetterPosition,
            quizIsFinished,
            setQuizIsFinished,
            correctAnswerCount,
            setCorrectAnswerCount,
            x,
            setX,
            y,
            setY,
          }}
        >
          {quizIsFinished && <SiQuizModal />}

          {!quizIsFinished && (
            <div className={classes.clue_letters_box__container}>
              <ClueLettersBox />
            </div>
          )}
          {!quizIsFinished && (
            <div className={classes.intersection_line__container}>
              <IntersectionLine />
            </div>
          )}
          {currentQuestion === 0 && (
            <div className={classes.first_question_text__container}>
              <p className={classes.first_question_text}>
                Stretch your brain before the siQuestions
              </p>
            </div>
          )}
          {currentQuestion === 0 ? (
            <div className={classes.open_question__container}>
              {questions.length > 0 && (
                <OpenQuestion question={questions[0].question} />
              )}
            </div>
          ) : (
            <div className={classes.question_container}>
              {questions.length > 0 && (
                <OpenQuestion question={questions[currentQuestion].question} />
              )}
            </div>
          )}
          {currentQuestion === 0 ? (
            <div className={classes.SiQuiz_button_container}>
              <AppButton
                icon={greenPlayIcon}
                styleP={{ color: "#36907E" }}
                onClick={StartQuizHandler}
              >
                Start siQuestions!
              </AppButton>
            </div>
          ) : (
            <div className={classes.variants_container}>
              <ul className={classes.variants_blocks}>
                {variantLetters.map((letter) => {
                  return (
                    <VariantBox
                      key={letter}
                      variantLetter={letter}
                      variantText={demo[letter]}
                    />
                  );
                })}
              </ul>
            </div>
          )}
          <div className={classes.numbers_box__container}>
            <NumbersBox />
          </div>
          {/* <div className={classes.menu_box__container}>
            <MenuBox sendDataToParent={sendDataToParent} />
          </div> */}
        </QuizContext.Provider>
      </div>
    </>
  );
};

export default Quiz;
