import * as React from "react";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import "../styles/ModalStyles/RegisterModal.css";
import { useAuth0 } from "@auth0/auth0-react";
import SubheaderText from "./SubHeaderTextModal";
import checkIcon from "../assets/checkIcon.png";
import AppButton from "../Components/GeneralUI/AppButton";
import { styled } from "@mui/material/styles";

const AppPopover = styled(Popover)(({ theme }) => ({
  "& .MuiPaper-root": {
    // padding: theme.spacing(1.5),
    paddingTop: "0rem",
    borderRadius: "1rem",
    marginTop: "1rem",
    // border: "2px solid blue",
  },
}));

export default function PopoverPopupState() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [popUpVisible, setPopUpVisible] = React.useState(false);

  if (!isAuthenticated) {
    return (
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState) => (
          <div>
            <AppButton
              styleButton={{
                backgroundColor: "#3565A3",
                height: "46px",
                padding: "0.3rem",
              }}
              styleP={{
                color: "#D9D9D9",
                fontSize: "16px",
              }}
              variant="contained"
              {...bindTrigger(popupState)}
            >
              WHY REGISTER?
            </AppButton>
            {/* <Button
              style={{ backgroundColor: "#3565A3" }}
              variant="contained"
              {...bindTrigger(popupState)}
            >
              Why register?
            </Button> */}
            <AppPopover
              className="rootElement"
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <div className="why_register_header">Why Register?</div>
              <SubheaderText
                icon={checkIcon}
                iconStyle="register_modal__subheader__icon"
                classNameText="register_modal__subheader__text"
                classNameDiv="register_modal__subheader__text__container"
              >
                Ranking is technically impossible without registration
              </SubheaderText>
              <SubheaderText
                icon={checkIcon}
                iconStyle="register_modal__subheader__icon"
                classNameText="register_modal__subheader__text"
                classNameDiv="register_modal__subheader__text__container"
              >
                Log in, if you want to receive the siReport of the day
              </SubheaderText>
              <div className="why_register_line"></div>
              <div className="why_register_buttons_container">
                <div
                  onClick={() => popupState.close()}
                  className="why_register_button"
                >
                  No, Thanks
                </div>
                <div
                  onClick={() => loginWithRedirect()}
                  className="why_register_button"
                >
                  Log In
                </div>
              </div>
              {/* <div className="register_modal__AppButton">
                <AppButton
                  styleButton={{
                    backgroundColor: "#A2AB28",
                    height: "36px",
                  }}
                  styleP={{
                    color: "#D9D9D9",
                    color: "white",
                    fontSize: "16px",
                  }}
                  onClick={() => loginWithRedirect()}
                >
                  Log in
                </AppButton>
              </div> */}
            </AppPopover>
          </div>
        )}
      </PopupState>
    );
  }
}
